<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :width="300"
      app
    >
      <left-sidebar />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      >
        <v-icon>
          {{ drawer ? 'mdi-menu-open' : 'mdi-menu' }}
        </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mr-2"
        @click="toggleFullScreen"
      >
        <v-icon>
          {{ fullScreenMode ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        class="d-flex d-sm-none"
      >
        <span class="mr-7">Affiliate Buddy</span>
      </v-toolbar-title>
      <!--drop-menu
        :small-buttons="false"
        :icon="`calendar-plus`"
        :actions-list="getTasksMenu"
        :item="{}"
        :left="false"
        :right="true"
      /-->
      <v-spacer />

      <div v-if="['xl', 'lg', 'md'].includes($vuetify.breakpoint.name) && updateCounter > 0">
        <v-badge
          color="primary"
          :content="updateCounter"
          overlap
          top
          left
        >
          <v-btn
            outlined
            small
            color="primary"
            @click.native="refreshPage"
          >
            <v-icon
              class=" blink-me mr-1"
              color="primary"
            >
              mdi-access-point
            </v-icon>
            {{ $t('Need refresh') }}
          </v-btn>
        </v-badge>
      </div>

      <v-spacer />

      <div v-if="['xl', 'lg', 'md'].includes($vuetify.breakpoint.name)">
        <socket-servers-status />
      </div>

      <dialog-event-log :dialog-event-log="dialogEventLog" />
      <drop-menu
        :actions-list="getSettingsMenu"
        :item="{}"
      />
      <!--locale-changer /-->
    </v-app-bar>
    <v-main fluid>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <dialog-confirmation ref="confirmationDialog" />
  </v-app>
</template>

<script>
// import { mapGetters } from 'vuex'

// import { toggleDialogEventLog, toggleDrawerMenu } from '@/store/mutation-types'

import resources from '@/resources/resources'
import resourceStore from '@/resources/resourceStore'

// import LocaleChanger from '@/components/LocaleChanger.vue'
import LeftSidebar from '@/components/navigation/LeftSidebar.vue'
import Settings2FA from '@/components/Settings2FA.vue'

import Storage from '@/libs/storage/Storage'
import SocketServersStatus from '../components/SocketServersStatus'
// import store from '@/store'
// import * as types from '@/store/mutation-types'

export default {
  name: 'AdminLayout',
  components: {
    SocketServersStatus,
    // LocaleChanger,
    LeftSidebar
  },
  data() {
    return {
      settingsMenu: [],
      tasksMenu: [],
      timeouts: [],
      //
      socketDisconnectedTimer: null,
      socketReconnectedTimer: null,
      //
      updateHealthyStatusTimer: null,
      //
      hoverSocketBtn: {},
      //
      updateCounter: 0,
      //
      fullScreenMode: false,
      prevDrawer: undefined,
      //
      reconnectingAlreadyStarted: false
    }
  },
  computed: {
    drawer: {
      get () {
        return this.$store.getters['adminLayout/drawer']
      },
      set (value) {
        Storage.setItem('drawer', Boolean(value))
        return this.$store.commit('adminLayout/toggleDrawerMenu', value)
      }
    },
    dialogEventLog: {
      get () {
        return this.$store.getters['adminLayout/dialogEventLog']
      },
      set (value) {
        Storage.setItem('dialogEventLog', Boolean(value))
        return this.$store.commit('adminLayout/toggleDialogEventLog', value)
      }
    },
    getSettingsMenu () {
      return this.settingsMenu
    },
    getTasksMenu () {
      return this.tasksMenu
    },
    user () {
      return this.$currentUser
    },
    socketConnected() {
      return this.$store.getters['socket/connected']
    }
  },
  watch: {
    $accessToken (val, old) {
      if (!!val && val !== old) {
        // this.socketReconnectedTimer = setInterval(() => {
        //   console.log('Socket reconnect [from $accessToken]')
        //   this.tryConnect()
        // }, 5000)
        this.tryConnect()
      }
    },
    socketConnected(val) {
      if (val === false) {
        this.socketReconnectedTimer = setInterval(() => {
          // console.log('Socket reconnect [from socketConnected]')
          this.tryConnect()
        }, 5000)
      }
    }
  },
  created () {
    this.tryConnect()
    this.updateHealthyStatus()

    // Resources load
    for (const resourceName in resources) {
      const resource = resources[resourceName]
      resourceStore(resource)
    }

    this.settingsMenu = [
      {
        icon: 'bullseye-arrow',
        text: 'Bull Dashboard',
        fn: () => {
          window.open(`${process.env.VUE_APP_API_URL}/bull`, '_blank')
        }
      },
      {
        icon: 'two-factor-authentication',
        text: this.user.twoFactorEnabled === false ? '2FA Enabling' : '2FA Disabling',
        color: 'primary',
        disabled: this.user.saml,
        fn: async () => {
          await this.open2FASettingsDialog()
        }
      },
      {
        icon: 'math-log',
        text: 'Event log',
        fn: () => (this.dialogEventLog = !this.dialogEventLog)
      },
      {
        divider: true
      },
      {
        icon: 'exit-run',
        text: 'Log out',
        // eslint-disable-next-line no-return-assign
        fn: async () => {
          await this.$store.dispatch('auth/logout')
          await this.$router.push({ name: 'login' })
        }
      }
    ]
  },
  async mounted () {
    this.$eventBus.$on(`role.${this.$currentUser.role}`, async (data) => {
      await this.$store.dispatch('auth/fetchUser')
      this.$store.commit('auth/SET_PERMISSIONS_BY_ROLE', data)
      this.updateCounter += 1
      // if (await this.$refs.confirmationDialog.open(
      //   'Refresh the page?',
      //   'The settings of the role to which your account is linked have been changed. You need to refresh your browser page for the changes to take effect.'
      // )) {
      //   await this.refreshPage()
      // }
    })

    this.$eventBus.$on('updateUserAuth', async (data) => {
      await this.$store.dispatch('auth/fetchUser')
      this.updateCounter += 1
    })

    if (this.user.twoFactorEnabled === false && !Storage.getItem('popup2fa')) {
      await this.open2FASettingsDialog()
      Storage.setItem('popup2fa', true)
    }
  },
  beforeDestroy () {
    this.socketDestroy()
  },
  methods: {
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.prevDrawer = this.drawer
        document.body.requestFullscreen()
        this.fullScreenMode = true
        this.drawer = false
        return false
      } else if (document.fullscreenElement) {
        document.exitFullscreen()
        this.fullScreenMode = false
        this.drawer = this.prevDrawer
      }
    },
    tryConnect () {
      /* Close old connections with socket server */
      this.socketDestroy()

      /* Connect to socket server */
      this.$socket.connect()

      /* Event listener that will be invoked a single */
      this.$socket.once('connect', () => {
        this.registerEventListeners(this.$socket)
      })
      this.$socket.on('connect', () => {
        if (this.socketReconnectedTimer) {
          clearInterval(this.socketReconnectedTimer)
          // console.log('Socket connection timer is clear [clear socketReconnectedTimer]')
        }

        if (this.socketDisconnectedTimer) {
          clearInterval(this.socketDisconnectedTimer)
          // console.log('Socket connection timer is clear [clear socketDisconnectedTimer]')
        }

        this.$store.commit('socket/socketConnect')
      })
      this.$socket.on('disconnect', async (reason) => {
        if (reason === 'io server disconnect' || reason === 'transport close') {
          if (this.$refreshToken) {
            this.socketDisconnectedTimer = setInterval(() => {
              this.$store.dispatch('auth/refreshAccessToken').then(() => {
              }).catch(() => {})
            }, 1000)
          }
        }
        this.$store.commit('socket/socketDisconnect', reason)
      })
    },
    registerEventListeners (socket) {
      this.$socket.emit('initAppState')
    },
    socketDestroy () {
      if (this.$socket) {
        this.$socket.off('connect')
        this.$socket.off('connect_error')
        this.$socket.off('disconnect')

        // this.$socket.off('socketServerPing')
        // this.$socket.off('message')

        this.$socket.disconnect()
      }
      clearInterval(this.updateHealthyStatusTimer)
    },
    updateHealthyStatus () {
      this.updateHealthyStatusTimer = setInterval(() => {
        this.$store.commit('socket/updateHealthyStatus')
      }, 1000)
    },
    async refreshPage() {
      await this.$router.go(0)
    },
    async open2FASettingsDialog() {
      if (this.user.saml === true) {
        return false
      }
      await this.$store.dispatch('dialog/dialog', {
        show: true,
        title: this.$t('dialog.settings2fa'),
        contentType: 'component',
        content: Settings2FA,
        maxWidth: 650,
        maxHeight: 650,
        withActionButtons: false
      })
    }
  }
}
</script>
