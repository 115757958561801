// noinspection JSValidateJSDoc

import qs from 'qs'
import axios from 'axios'
import helper from '../../utils/helper'
import paths from '../../paths'

/**
 * Get items list of resource (with pagination)
 * @param {String} name
 * @param {Object} options
 * @returns {axios}
 */
const list = async (name, options) => {
  return axios.get(`${helper.dotsToObject(name, paths).list}?${qs.stringify(options)}`)
}

/**
 * Create source item
 * @param {String} name
 * @param {Object} options
 * @returns {axios}
 */
const create = async (name, options) => {
  return axios.post(`${helper.dotsToObject(name, paths).create}`, options)
}

/**
 * Update source item
 * @param {String} name
 * @param {Object} options
 * @returns {axios}
 */
const update = async (name, options) => {
  const id = options.id
  options.id = undefined
  return axios.patch(`${helper.dotsToObject(name, paths).update}`.replace(':id', id), options)
}

/**
 * Remove source item
 * @param name
 * @param options
 * @returns {AxiosPromise<any>}
 */
const remove = async (name, options) => {
  try {
    if (Array.isArray(options)) {
      return axios.delete(`${helper.dotsToObject(name, paths).bulk.remove}`, { data: { ids: options.map(o => o.id) } })
    } else {
      const id = options.id
      return axios.delete(`${helper.dotsToObject(name, paths).remove}`.replace(':id', id))
    }
  } catch (e) {
    console.log(e)
  }
}

export default {
  list,
  create,
  update,
  remove
}
