import Vue from 'vue'
import store from '@/store'
import resourceService from '@/services/api/resource.service'
import Storage from '../libs/storage/Storage'

const defaultState = (name) => {
  return {
    [name]: {
      items: [],
      editedIndex: -1,
      editedItem: {},
      checkedItems: [],
      totalItems: 0,
      options: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 50,
        filter: {}
      },
      loading: false
    }
  }
}

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default (resource) => {
  const { name, actions } = resource

  /* -------------------------------------------------------------- */
  // FUNCTIONS
  /* -------------------------------------------------------------- */

  const _isAllowed = (action) => {
    return store.getters['auth/isAuthenticated'] === true &&
      (store.getters['auth/loggedInUser'].roleRights.some((r) => r.indexOf(`${name}.${action}`) > -1) &&
        actions.some((a) => a.indexOf(action) > -1))
  }

  /* -------------------------------------------------------------- */
  // ACTIONS
  /* -------------------------------------------------------------- */

  const _actions = {}

  const busy = []

  if (_isAllowed('list')) {
    _actions.list = async (context) => {
      // If the user is not authorized - the next code execution is canceled
      if (store.getters['auth/isAuthenticated'] !== true) {
        return false
      }

      if (busy[name] === true) {
        return false
      }
      context.commit('_setLoading', true)
      let { sortBy, sortDesc, page, itemsPerPage, query, status, filter } = context.state[name].options
      const params = {
        page: page || 1,
        limit: itemsPerPage || 50,
        query: query || null,
        filter: filter || {}
      }
      if (typeof sortBy[0] !== 'undefined' && typeof sortDesc[0] !== 'undefined') {
        params.sortBy = `${sortBy[0]}:${sortDesc[0] === true ? 'desc' : 'asc'}`
      }
      if (status) {
        params.status = status
      }
      try {
        busy[name] = true
        const response = await resourceService.list(name, params)
        if (response.status === 200) {
          const data = response.data
          const items = data.results
          const totalItems = data.totalResults
          if (data.totalPages < page) {
            page = data.totalPages
          }
          context.commit('_setItems', { items, page, itemsPerPage, totalItems })
        }
      } catch (e) {
        await Promise.reject(e)
      } finally {
        context.commit('_setLoading', false)
        busy[name] = false
      }
    }
  }

  if (_isAllowed('create')) {
    _actions.create = async ({ commit }, item) => {
      commit('_setLoading', true)
      try {
        const response = await resourceService.create(name, item)
        if (response.status === 201) {
          commit('_setItem', response.data)
          await Promise.resolve(response.data)
        }
      } catch (e) {
        await Promise.reject(e)
      } finally {
        commit('_setLoading', false)
      }
    }
  }

  if (_isAllowed('update')) {
    _actions.update = async ({ commit }, item) => {
      commit('_setLoading', true)
      try {
        const response = await resourceService.update(name, item)
        if (response.status === 200) {
          commit('_setItem', response.data)
          await Promise.resolve(response.data)
        }
      } catch (e) {
        await Promise.reject(e)
      } finally {
        commit('_setLoading', false)
      }
    }
  }

  if (_isAllowed('remove')) {
    _actions.remove = async ({ commit, dispatch }, item) => {
      commit('_setLoading', true)
      try {
        const response = await resourceService.remove(name, item)
        if (response.status === 204) {
          commit('setCheckedItems', { value: false, item })
          await dispatch('list')
        }
      } catch (e) {
        await Promise.reject(e)
      } finally {
        commit('_setLoading', false)
      }
    }
  }

  /* -------------------------------------------------------------- */
  // MUTATIONS
  /* -------------------------------------------------------------- */

  const _mutations = {}

  _mutations.resetState = (state) => {
    Object.assign(state, getDefaultState(defaultState(name)))
  }

  if (_isAllowed('list')) {
    _mutations.setOptions = (state, payload) => {
      state[name].options = payload
      Storage.setItem('itemsPerPage', payload.itemsPerPage || 50)
    }

    _mutations._setItems = (state, { items, page, itemsPerPage, totalItems }) => {
      Vue.set(state[name], 'items', items)
      Vue.set(state[name].options, 'page', page)
      Vue.set(state[name].options, 'itemsPerPage', itemsPerPage)
      state[name].totalItems = totalItems
    }

    _mutations.clearList = (state) => {
      state[name].items = []
    }

    _mutations.setCheckedItems = (state, data) => {
      let index
      if (data.value === true) {
        index = state[name].checkedItems.findIndex((ci) => ci._id === data.item._id)
        if (index < 0) {
          state[name].checkedItems.push(data.item)
        }
      } else if (data.value === false) {
        index = state[name].checkedItems.findIndex((ci) => ci._id === data.item._id)
        if (index > -1) {
          state[name].checkedItems.splice(index, 1)
        }
      }
    }
  }

  if (_isAllowed('create') || _isAllowed('update')) {
    _mutations._setItem = (state, item) => {
      state[name].editedIndex = state[name].items.map(i => i.id).indexOf(item.id)
      if (state[name].editedIndex > -1) {
        Vue.set(state[name].items, state[name].editedIndex, item)
      } else {
        state[name].items.unshift(item)
        state[name].totalItems += 1
      }
    }
  }

  _mutations.clearCheckedItems = (state) => {
    state[name].checkedItems = []
  }

  _mutations.setEditedItem = (state, data) => {
    state[name].editedItem = data
  }

  _mutations.saveItem = (state, item) => {
    if (item._id === undefined && item.id === undefined) {
      return true
    }
    const id = item._id || item.id
    const itemIndex = state[name].items.map(i => i.id).indexOf(id)
    if (itemIndex > -1) {
      Vue.set(state[name].items, itemIndex, item)
    } else {
      // state.items.unshift(item)
      // state.totalItems += 1
      state[name].items.unshift(item)
      state[name].totalItems += 1
    }
    return true
  }

  _mutations.updateItem = (state, item) => {
    if (item._id === undefined && item.id === undefined) {
      return true
    }
    const id = item._id || item.id
    const itemIndex = state[name].items.map(i => i.id).indexOf(id)
    if (itemIndex > -1) {
      Vue.set(state[name].items, itemIndex, item)
    }
    return true
  }

  _mutations._setLoading = (state, loading) => {
    state[name].loading = loading
  }

  _mutations.lockItem = (state, item) => {
    let id
    if (typeof item === 'object') {
      id = item.id
    } else if (typeof item === 'string') {
      id = item
    }
    const foundItem = state[name].items.find(i => i._id === id)
    if (foundItem) {
      Vue.set(foundItem, 'locked', true)
      Vue.set(foundItem, 'loading', true)
      Vue.set(foundItem, 'disabled', true)
      Vue.set(foundItem, 'syncLoading', true)
      Vue.set(foundItem, 'isSelectable', false)
    }
  }

  _mutations.unlockItem = (state, item) => {
    let id
    if (typeof item === 'object') {
      id = item.id
    } else if (typeof item === 'string') {
      id = item
    }
    const foundItem = state[name].items.find(i => i._id === id)
    if (foundItem) {
      Vue.set(foundItem, 'locked', false)
      Vue.set(foundItem, 'loading', false)
      Vue.set(foundItem, 'disabled', false)
      Vue.set(foundItem, 'syncLoading', false)
      Vue.set(foundItem, 'isSelectable', true)
    }
  }

  /* -------------------------------------------------------------- */
  // GETTERS
  /* -------------------------------------------------------------- */

  const _getters = {}

  if (_isAllowed('list')) {
    _getters.options = (state) => {
      return state[name].options
    }
    _getters.items = (state) => {
      return state[name].items.slice(0, state[name].options.itemsPerPage)
    }
    _getters.totalItems = (state) => {
      return state[name].totalItems
    }
    _getters.checkedItems = (state) => {
      return state[name].checkedItems
    }
    _getters.getEditedItem = (state) => {
      return state[name].editedItem
    }
  }
  _getters.loading = (state) => {
    return state[name].loading
  }

  /* -------------------------------------------------------------- */
  // MODULE
  /* -------------------------------------------------------------- */

  const resourceStoreModule = {
    strict: true,
    namespaced: true,
    name: name,
    state: defaultState(name),
    getters: _getters,
    actions: _actions,
    mutations: _mutations
  }

  if (!(store && store.state && store.state[`__${name}`])) {
    store.registerModule(`__${name}`, resourceStoreModule)
  }
}
