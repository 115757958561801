export default {
  tags: {
    name: 'tags',
    actions: ['list', 'view', 'create', 'update' /*, 'remove' */, 'active']
  },
  'affiliates.management': {
    name: 'affiliates.management',
    actions: ['list', 'view', 'create', 'update', 'remove', 'import']
  },
  'affiliates.teams': {
    name: 'affiliates.teams',
    actions: ['list', 'view', 'create', 'update', 'remove', 'permissions']
  },
  'users.management': {
    name: 'users.management',
    actions: ['list', 'create', 'update', 'remove']
  },
  'domains.management': {
    name: 'domains.management',
    actions: ['list', /* 'create',  */ 'update', 'remove']
  },
  'domains.providers': {
    name: 'domains.providers',
    actions: ['list', 'create', 'update', 'remove']
  },
  'domains.cloudflare': {
    name: 'domains.cloudflare',
    actions: ['list', 'create', 'update', 'remove']
  },
  'hosting.providers': {
    name: 'hosting.providers',
    actions: ['list', 'create', 'update', 'remove']
  },
  'hosting.servers': {
    name: 'hosting.servers',
    actions: ['list', 'create', 'update', 'remove']
  },
  emails: {
    name: 'emails',
    actions: ['list', 'create', 'update', 'remove']
  },
  services: {
    name: 'services',
    actions: ['list', 'create', 'update', 'remove', 'auth']
  },
  proxies: {
    name: 'proxies',
    actions: ['list', 'create', 'update', 'remove']
  },
  settings: {
    name: 'settings',
    actions: ['list', 'create', 'update', 'remove']
  },
  'tasks.generator': {
    name: 'tasks.generator',
    actions: ['list', 'create', 'update', 'remove']
  },
  'tasks.upload': {
    name: 'tasks.upload',
    actions: ['list', 'create', 'update', 'remove']
  },
  'tasks.wpg': {
    name: 'tasks.wpg',
    actions: ['list', 'create', 'update', 'remove']
  },
  'tasks.cloning': {
    name: 'tasks.cloning',
    actions: ['list', 'create', 'update', 'remove']
  },
  'tasks.lpg': {
    name: 'tasks.lpg',
    actions: ['list', 'create', 'update', 'remove']
  },
  'tasks.sdr': {
    name: 'tasks.sdr',
    actions: ['list', 'create', 'remove']
  }
}
